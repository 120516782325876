<template>
	<div class="footCon">
		<img src="../../assets/img/pc/logo.png" alt="">
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {}
	}
</script>

<style scoped lang="less">
	.footCon{
		width: 100%;
		height: 232px;
		background-color: #545D48;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: 274px;
			height: 61px;
		}
	}
</style>