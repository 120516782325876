<template>
	<div class="videoItem" @click="toLive(item.type, item.course_id)">
		<div class="coverCon">
			<img :src="item.url" class="cover" alt="">
			<img src="../../assets/img/pc/play.png" class="play" alt="">
		</div>
		<div class="videoMsg">
			<div class="kcwcsCon">
				<span>课程完成度</span>
				<span>{{item.progress}}%</span>
			</div>
			<div class="progress">
				<el-progress :percentage="parseInt(item.progress)" color="#857D43" define-back-color="#E1E2D1"
					:show-text="false"></el-progress>
			</div>
			<div class="titCon">
				<span class="state" v-if="item.type == 0" style="background: #FF0000;">课程未开始</span>
				<span class="state" v-if="item.type == 1" style="background: #2dd296;">直播中</span>
				<span class="state" v-if="item.type == 3">回放中</span>
				<span class="state" v-if="item.type == -1" style="background: #FF0000;">课程已结束</span>
				<span class="tit">{{ item.course_name }}</span>
			</div>
			<div class="btnCon">
				<div class="zjCon">
					<div class="zj">授课专家</div>
					<span class="name">{{item.speaker}}</span>
				</div>
				<div class="kjxz">
					<i class="el-icon-download"></i>
					<span class="txt" @click.stop="download(item)">课件下载</span>
				</div>
			</div>
			<div class="jianjie">{{ item.describe }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['item'],
		data() {
			return {}
		},
		mounted() {},
		methods: {
			// 下载课件
			download(item) {
				if (item.enclosure) {
					let a = document.createElement('a');
					a.href = item.enclosure;
					a.download = item.enclosure_name ? item.enclosure_name : "课件";
					a.target = "_blank";
					a.click();
				} else {
					this.$toast.fail("暂无课件")
				}
			},
			// 查看详情
			toLive(type, course_id) {
				console.log(type, course_id)
				if (type == 0) {
					this.$toast.fail("课程未开始")
					return;
				}
				if (type == -1) {
					this.$toast.fail("课程已结束")
					return;
				}
				this.$router.push('/pc/video?id=' + course_id);
			},
		}
	}
</script>

<style scoped lang="less">
	.videoItem {
		width: 378px;
		margin-right: 33px;
		margin-bottom: 32px;
		overflow: hidden;
		border-radius: 20px;
		box-shadow: 0px 10px 7px 0px rgba(192, 199, 162, 0.31);
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.coverCon {
			width: 100%;
			// height: 204px;
			overflow: hidden;
			position: relative;

			.cover {
				width: 100%;
				// height: 100%;
				// object-fit: cover;
			}

			.play {
				width: 41px;
				height: 41px;
				position: absolute;
				bottom: 11px;
				right: 35px;
			}
		}

		.videoMsg {
			width: 100%;
			padding: 15px 35px;
			box-sizing: border-box;

			.kcwcsCon {
				font-size: 12px;
				color: #656565;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.titCon {
				width: 100%;
				display: flex;
				align-items: center;
				margin-top: 20px;

				.state {
					padding:3px 10px;
					background: #FCA106;
					border-radius: 5px;
					color: #fff;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 7px;
				}

				.tit {
					width: 210px;
					font-size: 18px;
					color: #656565;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.btnCon {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 15px;
				margin-bottom: 13px;

				.zjCon {
					display: flex;
					align-items: center;

					.zj {
						padding:3px 10px;
						background: #5B7565;
						border-radius: 5px;
						font-size: 14px;
						color: #FFFFFF;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.name {
						color: #656565;
						font-size: 16px;
						margin-left: 7px;
					}
				}

				.kjxz {
					padding:3px 10px;
					background: #856543;
					border-radius: 5px;
					font-size: 12px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.jianjie {
				font-size: 14px;
				color: #656565;
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				text-indent: 2em;
			}
		}
	}
	.videoItem:nth-child(3n){
		margin-right: 0;
	}
</style>