<template>
	<div>
		<div class="nav_box">
			<img src="@/assets/img/pc/logo.png" alt="古韵新书" class="logo">
			<div class="navCon">
				<router-link to="/pc/home">首页</router-link>
				<van-popover v-model="showPopover" trigger="click" placement="bottom-end">
					<div class="history_list">
						<div class="history_title">{{ name }}的学习记录</div>
						<div class="yixuexi">
							<span>已学习</span>
							<div class="waicon"><span class="waibar" :style="{'width':`${barNum}%`}"></span></div>
							<span class="barnum">{{barNum}}%</span>
						</div>
						<div v-for="(item, index) in list" :key="index" class="history_item"
							@click="itemClick(item, index)">
							<div class="nameCon">
								<span>{{ item.course_name }}</span>
								<img src="@/assets/img/pc/play2.png" alt="">
							</div>
							<p v-if="!item.studyStatus" class="history_item_time">时长 {{ item.time }}</p>
							<p v-if="item.studyStatus" class="history_item_finish">已学完</p>
						</div>
						<div class="history_more" @click="moreClick">查看更多</div>
					</div>
					<template #reference>
						<div class="top_user" :class="{'router-link-exact-active': $route.path=='/pc/history'}">
							<span>学习记录</span>
							<van-icon :class="{'van_icon_active': showPopover}" name="play" />
						</div>
					</template>
				</van-popover>
				<span v-if="showDownload" class="xzzs" @click="downloadImg">下载证书</span>
			</div>
		</div>
		<div class="bannerCon">
			<img class="banner" src="@/assets/img/pc/banner.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 学习记录
				barNum: 0,
				showPopover: false,
				name: "用户",
				userId: 0,
				// 是否学习完成
				showDownload: false,
				// 学习记录
				list: [],
			}
		},
		mounted() {
			// 学习进度
			this.findProgress();
			// 是否学习完成
			this.findTime();
			// 学习记录
			this.init();
		},
		methods: {
			// 学习进度
			findProgress() {
				this.$api.findProgress().then(res => {
					this.barNum = res.data;
				});
			},
			// 下载证书
			downloadImg() {
				let ls = localStorage.getItem('user');
				this.$api.downloadImg(JSON.parse(ls).user_id).then(res => {
					if (res.code === 200) {
						let a = document.createElement('a');
						a.href = res.data;
						a.download = JSON.parse(ls).name + '.jpg';
						a.target = "_blank";
						a.click();
						this.$toast.success("下载成功")
					}
				});
			},
			// 是否学习完成
			findTime() {
				let ls = localStorage.getItem('user');
				this.$api.findTime(JSON.parse(ls).user_id).then(res => {
					if (res.msg === '学习完成') {
						this.showDownload = true;
					}
				});
			},
			// 学习记录
			async init() {
				const res = await this.$api.jntsFindHistory();
				let data = res.data.list;
				data.map(item => {
					item.time = this.formatTime(item.duration);
					item.studyStatus = item.video_time <= 0 || parseInt(item.duration) >= parseInt(item.video_time);
				})
				if (data.length > 3) {
					data.length = 3
				}
				this.list = data;
				let ls = localStorage.getItem('user');
				this.userId = JSON.parse(ls).user_id;
				if (ls) {
					this.name = JSON.parse(ls).name;
				}
			},
			// 跳转详情
			itemClick(item, index) {
				this.$router.push('/pc/video?id=' + item.course_id);
			},
			// 时间格式化
			formatTime(num) {
				if (num <= 0) {
					return '00:00:00';
				} else {
					let hh = parseInt(num / 3600); //小时
					let shh = num - hh * 3600;
					let ii = parseInt(shh / 60);
					let ss = shh - ii * 60;
					return (hh < 10 ? '0' + hh : hh) + ':' + (ii < 10 ? '0' + ii : ii) + ':' + (ss < 10 ? '0' + ss : ss);
				}
			},
			// 学习记录
			moreClick() {
				this.$router.push('/pc/history');
			}
		}
	}
</script>

<style scoped lang="less">
	.bannerCon {
		width: 100%;
		.banner {
			width: 100%;
		}
	}

	.nav_box {
		width: 1200px;
		display: flex;
		align-items: center;
		padding: 30px 20px;
		box-sizing: border-box;
		margin: 0 auto;
		.logo {
			width: 180px;
		}

		.navCon {
			margin-top: -10px;

			a {
				font-size: 18px;
				color: #B5802B;
				margin-left: 60px;
			}

			.router-link-exact-active {
				padding-bottom: 3px;
				border-bottom: 3px solid #B5802B;
			}

			.top_user {
				display: flex;
				align-items: center;
				margin-left: 60px;

				span {
					color: #B5802B;
					font-size: 18px;
					cursor: pointer;
				}

				/deep/ .van-icon {
					margin-left: 10px;
					font-size: 18px;
					color: #B5802B;
					transform: rotate(90deg);
					transition: all .3s;
					cursor: pointer;
				}

				.van_icon_active {
					transform: rotate(270deg);
				}
			}

			.xzzs {
				margin-left: 60px;
				color: #B5802B;
				font-size: 18px;
				cursor: pointer;
			}
		}
	}

	.history_list {
		width: 230px;

		.history_title {
			font-size: 16px;
			background: #f9f7f8;
			color: #B5802B;
			padding: 10px 20px;
		}

		.yixuexi {
			font-size: 12px;
			color: #B5802B;
			background: #f9f7f8;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.waicon {
				width: 90px;
				margin: 0 10px;
				height: 4px;
				position: relative;
				background-color: #e7e9e8;

				.waibar {
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: #BA983F;
				}
			}
		}

		.history_item {
			padding: 10px 20px;
			cursor: pointer;

			.nameCon {
				display: flex;
				justify-content: space-between;
				align-items: center;

				span {
					display: inline-block;
					width: 165px;
					color: #000000;
					font-size: 14px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				img {
					width: 15px;
					margin-left: 5px;
				}
			}

			.history_item_time {
				font-size: 12px;
				color: #6d6d6d;
				margin-top: 5px;
			}

			.history_item_finish {
				font-size: 12px;
				color: #B5802B;
				margin-top: 5px;
			}
		}

		.history_more {
			padding: 10px 20px;
			text-align: center;
			color: #8d8d8d;
			font-size: 12px;
			cursor: pointer;
		}
	}
</style>